.textblock {
    display: flex;
    align-items: center;

    &--images {
        display: flex;
        align-items: flex-end;
        margin-right: 160px;

        > .image {
            &:first-of-type {
                width: 800px;
                height: 800px;
            }
    
            &:nth-of-type(2) {
                width: 480px;
                height: 320px;
                margin-left: -320px;
                margin-bottom: 120px;
            }
        }
    }

    &--text {
        max-width: 480px;
        margin-right: 80px;

        h2 {
            line-height: 1;
        }

        .text {
            margin-top: 40px;
        }
        
        .cta {
            margin-top: 40px;
        }
    }

    &.fullscreen {
        width: 100vw;

        &.reverse {
            padding-left: 30px;
        }
        
        &:not(.reverse) {
            padding-right: 30px;
        }
    }

    &:not(.fullscreen) {
        max-width: 1620px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 30px;
        padding-right: 30px;
    }

    &.reverse {
        flex-direction: row-reverse;

        .textblock {
            &--images {
                margin-left: 160px;
                margin-right: 0;
                flex-direction: row-reverse;

                > .image {
                    &:nth-of-type(2) {
                        margin-left: 0px;
                        margin-right: -320px;
                    }
                }
            }
        }
    }

    @include mq($from: 2200px) {
        max-width: 1720px;
        margin-left: auto;
        margin-right: auto;
    }

    @include mq($until: 1700px) {
        &--images {
            margin-right: 100px;

            .image {
                &:nth-of-type(1) {
                    width: 650px;
                    height: 650px;
                }
            }
        }
    }

    @include mq($until: 1440px) {
        &--images {
            margin-right: 80px;

            .image {
                &:nth-of-type(1) {
                    width: 550px;
                    height: 550px;
                }
                
                &:nth-of-type(2) {
                    width: 420px;
                    height: 260px;
                }
            }
        }

        &.reverse {
            .textblock {
                &--images {
                    margin-left: 80px;    
                }

                &--text {
                    margin-right: 0;
                }
            }
        }
    }

    @include mq($until: 1200px) {
        &--images {
            margin-right: 60px;

            .image {
                &:nth-of-type(1) {
                    width: 450px;
                    height: 450px;
                }
                
                &:nth-of-type(2) {
                    width: 380px;
                    height: 220px;
                    margin-bottom: 50px;
                }
            }
        }

        &--text {
            max-width: 400px;
        }
    }

    @include mq($until: 1000px) {
        &--text {
            .text, .cta {
                margin-top: 20px;
            }
        }

        &--images {
            .image {
                &:nth-of-type(1) {
                    width: 400px;
                    height: 400px;
                }

                &:nth-of-type(2) {
                    width: 340px;
                    height: 190px;
                    margin-bottom: -20px;
                }
            }
        }
    }

    @include mq($until: 900px) {
        &--text {
            margin-right: 0;
        }
    }
   
    @include mq($until: 850px) {        
        &.reverse, &:not(.reverse) {
            flex-direction: column;
            align-items: flex-start;
            padding-left: 30px;   
    
            .textblock {
                &--text {
                    margin-top: 70px;
                    margin-left: auto;         
                }
            }
        }

        &.reverse {
            padding-right: 30px;

            .textblock {
                &--images {
                    margin-left: 0;
                }
            }
        }
    }

    @include mq($until: $viewport--sm) {
        &--text {
            margin-top: 50px !important;
        }

        &--images {
            width: 100%;
        }
    }
    
    @include mq($until: 450px) {
        &--images {
            margin-right: 0;
            .image {
                &:nth-of-type(1) {
                    width: 100%;
                }
                
                &:nth-of-type(2) {
                    display: none;
                }
            }
        }

        &--text {
            margin-top: 30px !important;
            margin-left: 0;
            margin-right: auto;         
        }
    }
}

.textblock + .textblock {
    margin-top: 180px;

    @include mq($until: $viewport--md) {
        margin-top: 120px;
    }
    
    @include mq($until: $viewport--sm) {
        margin-top: 60px;
    }
}