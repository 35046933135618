.newsletter {
    background-color: $cc-goldShade;
    padding-bottom: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    &.extra-space {
        padding-top: 250px;
        padding-bottom: 250px;
    }

    &.white-background {
        background-color: $cc-white;
    }

    h2 {
        margin-bottom: 30px;
    }

    .error {
        color: $cc-red;
    }

    form {
        width: 100%;
        max-width: 960px;
        
        .row {
            display: flex;
            align-items: center;

            .cta {
                width: unset !important;
            }
            
            &.row-1 {
                > * {        
                    &:nth-of-type(1) {
                        width: 30%;
                    }
                    
                    &:nth-of-type(2) {
                        width: 70%;
                        margin: 0 10px;
                    }
                    
                    input {
                        width: 100%;
                    }
                }
    
                @include mq($until: $viewport--md) {
                    display: grid;
                    grid-template-columns: 40% 60%;
                    grid-template-rows: repeat(2, auto);
                    grid-template-areas: 'voornaam email''button button';
    
                    > * {
                        &:nth-child(1) {                   
                            width: 100%;
                            grid-area: voornaam;
                        }
                        
                        &:nth-child(2) {
                            width: 100%;
                            padding-right: 0;
                            grid-area: email;
                        }                        
                    }

                    button {
                        max-width: 120px;
                        line-height: 1;
                        margin-top: 10px;
                        grid-area: button;
                    }
                }
    
                @include mq($until: $viewport--sm) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
    
                    > * {
                        &:nth-of-type(2) {                                                    
                            margin: 0;
                            margin-top: 10px;
                        }
                    }
                }
            }
    
            &.row-2 {
                margin-top: 10px;
    
                p {
                    font-size: 12px;
                    margin-bottom: 0;
                    color: $cc-darkGrey;
                    font-family: $font-base;
                }
            }
        }
    }
    
    @include mq($until: $viewport--sm) {
        padding-bottom: 80px;
    }
}