.Footer {
    background-color: $cc-white;
    border-top: 1px solid $cc-grey;
    
    .content {
        max-width: 1440px;
        width: 100%;
        padding: 50px 30px;
        margin: 0 auto;   
        display: flex;
        justify-content: space-between;     
   
        .left {
            display: flex;
            margin-right: 50px;

            .logo {
                margin-right: 120px;
            }

            .possibilities {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: -40px;
                
                .possibility {
                    margin-bottom: 40px;
                    display: flex;
                    align-items: center;
                    height: 51px;

                    .title {                        
                        font-size: 16px;
                        font-weight: 500;
                        color: $cc-primary;
                        font-family: $font-base;
                        text-decoration: none;
                        margin-bottom: 10px;
                        display: flex;
                    }
                    
                    ul {
                        padding-left: 0;
                        margin-bottom: 0;
            
                        li {
                            list-style: none;

                            a {
                                color: $cc-darkGrey;
                                text-decoration: none;
                                display: flex;
                                align-items: center;
                                font-family: $font-base;
                                font-size: 12px;

                                .stars {
                                    letter-spacing: -2px;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }

                    &:not(:last-of-type) {
                        margin-right: 50px;
                    }
                }
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            * {
                text-align: right;
            }

            .socials {
                display: flex;
                margin-bottom: 0;

                li {
                    list-style: none;
                    margin-left: 10px;
                    margin-bottom: 10px;
                    max-width: 25px;
                    max-height: 30px;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .languages {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-top: 10px;

                li {
                    list-style: none;

                    a {
                        color: $cc-darkGrey;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        font-family: $font-base;
                        font-size: 12px;
                        transition: color .4s ease;
            
                        &.active {
                            color: $cc-primary;
                        }
            
                        span {
                            transition: color .4s ease;
                        }
            
                        &:hover {
                            &.page {
                                color: $cc-primary;
                            }
            
                            span {
                                color: $cc-primary;
                            }
                        }
                    }
                }
            }
        }

        @include mq($until: $viewport--md) {
            .left {
                display: flex;
                flex-direction: column;

                .logo {
                    margin-bottom: 40px;
                }
            }
        }
        
        @include mq($until: $viewport--sm) {
            flex-direction: column;

            .left {
                display: flex;
                flex-direction: column;
                margin-right: 0;

                .possibilities {
                    .possibility {
                        margin-bottom: 20px;
                        height: auto;

                        &:not(:last-of-type) {
                            margin-right: 10px;
                        }
                    }
                }
            }

            .right {
                align-items: flex-start;
                margin-top: 40px;

                * {
                    text-align: left;
                }

                .socials {
                    li {
                        margin-left: 0;
                        margin-right: 10px;
                    }
                }

                .languages {
                    align-items: flex-start;
                    margin-bottom: 0;
                }

                .socials, .languages {
                    padding-left: 0;
                }
            }
        }
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1440px;
        width: 100%;
        padding: 30px 30px 50px;
        margin-left: auto;
        margin-right: auto;

        ul {
            padding: 0;
            display: flex;
            align-items: center;
            margin: 0;

            li {
                list-style: none;
                margin-bottom: 10px;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }

        a {
            color: $cc-darkGrey;
            text-decoration: none;
            display: flex;
            align-items: center;
            font-family: $font-base;
            font-size: 12px;
            transition: color .4s ease;

            &.active {
                color: $cc-primary;
            }

            span {
                transition: color .4s ease;
            }

            &:hover {
                &.page {
                    color: $cc-primary;
                }

                span {
                    color: $cc-primary;
                }
            }
        }

        .right {
            margin-bottom: 10px;
        }

        @include mq($until: $viewport--sm) {
            flex-direction: column;
            padding-top: 0;

            ul {
                flex-wrap: wrap;
                justify-content: center;

                li {
                    margin-left: 10px;
                    margin-right: 10px !important;
                }
            }
        }
    }
}