.Maps {
    height: 800px;

    .gm {
        &-style-iw {
            border-radius: 0;
            padding: 0 !important;
            max-height: unset;
            
            &-d {
                max-height: inherit !important;
                overflow: hidden !important;
            }

            button {
                display: none !important;
            }
        }
    }

    #gm {
        

        &_content {
            display: flex;
            flex-direction: column;

            img {
                width: 240px;
                height: 160px;
                object-fit: cover;
            }

            .info {
                padding: 25px 30px;

                h1 {
                    font-size: 20px;
                    font-family: $font-base;
                    font-weight: 400;
                    color: $cc-darkGrey;
                }

                .address {
                    margin-top: 20px;

                    * {
                        font-size: 14px;
                        line-height: 18px;
                        font-family: $font-base;
                        color: $cc-darkGrey;
                        font-weight: 400;
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
                
                .site {
                    margin-top: 20px;
                    font-size: 14px;
                    font-family: $font-base;
                    font-weight: 400;
                    color: $cc-primary;
                    text-decoration: none;
                    font-size: 14px;
                    text-transform: lowercase;
                    border-bottom: 1px solid $cc-primary;
                    display: flex;
                    width: fit-content;
                }
            }
        }
    }
}