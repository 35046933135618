/* Settings: Fonts */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-base:                      "GothamPro", sans-serif !default; //200, 300, 400, 500, 600, 700, 800, 900
$font-secondary:                      "Didonesque", sans-serif !default; //200, 300, 400, 500, 600, 700, 800, 900

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham-Light.woff') format('woff');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-LightIta.woff2') format('woff2'),
        url('../fonts/Gotham-LightIta.woff') format('woff');
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Book.woff2') format('woff2'),
        url('../fonts/Gotham-Book.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-BookIta.woff2') format('woff2'),
        url('../fonts/Gotham-BookIta.woff') format('woff');
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/Gotham-Medium.woff') format('woff');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-MediumIta.woff2') format('woff2'),
        url('../fonts/Gotham-MediumIta.woff') format('woff');
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-BoldIta.woff2') format('woff2'),
        url('../fonts/Gotham-BoldIta.woff') format('woff');
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Black.woff2') format('woff2'),
        url('../fonts/Gotham-Black.woff') format('woff');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-BlackIta.woff2') format('woff2'),
        url('../fonts/Gotham-BlackIta.woff') format('woff');
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Ultra.woff2') format('woff2'),
        url('../fonts/Gotham-Ultra.woff') format('woff');
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-UltraIta.woff2') format('woff2'),
        url('../fonts/Gotham-UltraIta.woff') format('woff');
    font-style: italic;
    font-weight: 800;
}

@font-face {
    font-family: "GothamPro";
    src: url('../fonts/GothamPro-Light.woff2') format('woff2'),
        url('../fonts/GothamPro-Light.woff') format('woff');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "GothamPro";
    src: url('../fonts/GothamPro-LightIta.woff2') format('woff2'),
        url('../fonts/GothamPro-LightIta.woff') format('woff');
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: "GothamPro";
    src: url('../fonts/GothamPro.woff2') format('woff2'),
        url('../fonts/GothamPro.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "GothamPro";
    src: url('../fonts/GothamPro-Italic.woff2') format('woff2'),
        url('../fonts/GothamPro-Italic.woff') format('woff');
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: "GothamPro";
    src: url('../fonts/GothamPro-Medium.woff2') format('woff2'),
        url('../fonts/GothamPro-Medium.woff') format('woff');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "GothamPro";
    src: url('../fonts/GothamPro-MediumIta.woff2') format('woff2'),
        url('../fonts/GothamPro-MediumIta.woff') format('woff');
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: "GothamPro";
    src: url('../fonts/GothamPro-Bold.woff2') format('woff2'),
        url('../fonts/GothamPro-Bold.woff') format('woff');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "GothamPro";
    src: url('../fonts/GothamPro-BoldIta.woff2') format('woff2'),
        url('../fonts/GothamPro-BoldIta.woff') format('woff');
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: "GothamPro";
    src: url('../fonts/GothamPro-Black.woff2') format('woff2'),
        url('../fonts/GothamPro-Black.woff') format('woff');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "GothamPro";
    src: url('../fonts/GothamPro-BlackIta.woff2') format('woff2'),
        url('../fonts/GothamPro-BlackIta.woff') format('woff');
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: "GothamPro";
    src: url('../fonts/GothamPro-Ultra.woff2') format('woff2'),
        url('../fonts/GothamPro-Ultra.woff') format('woff');
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: "GothamPro";
    src: url('../fonts/GothamPro-UltraIta.woff2') format('woff2'),
        url('../fonts/GothamPro-UltraIta.woff') format('woff');
    font-style: italic;
    font-weight: 800;
}

@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-Black.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-Black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-BlackItalic.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-BlackItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-MediumItalic.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-BoldItalic.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Didonesque Roman';
    src: url('../fonts/DidonesqueLite-Italic.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-Medium.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-Bold.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Didonesque';
    src: url('../fonts/DidonesqueLite-Roman.woff2') format('woff2'),
        url('../fonts/DidonesqueLite-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



