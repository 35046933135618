.WpHotelsHomePage {
    .page-header {
        height: 100vh;
        margin-top: -120px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        h1 {
            font-size: 80px;
            max-width: 800px;
            text-align: center;
            color: $cc-white;
            line-height: 80px;
            
            @include mq($until: 1000px) {
                font-size: 50px;
                line-height: 66px;        
            }
            
            @include mq($until: $viewport--md) {
                font-size: 42px;     
                line-height: 62px;
            }
           
            @include mq($until: $viewport--sm) {
                font-size: 36px;        
                line-height: 54px;        
            }

            @include mq($until: 440px) {
                 font-size: 28px;      
                line-height: 32px;
            }
        }

        .cta {
            margin-top: 60px;

            @include mq($until: $viewport--sm) {
                margin-top: 30px;       
            }
        }

        .star {
            position: absolute;           
            bottom: 60px;
            max-width: 60px;

            svg {
                width: 100%;
                height: 100%;
            }

            @include mq($until: $viewport--sm) {
                width: 40px;
                height: 40px;   
            }
        }
    }

    .intro-block {
        padding-top: 80px;
        background-color: $cc-goldShade;
    }
}