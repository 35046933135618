.parallax {
    &-container {
        position: relative;
        width: 100%;
        overflow: hidden;   
    }
    
    &-content {
        height: 120% !important;
        transform: scale(1.02);
    }
}