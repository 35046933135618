.Header {    
    .hamburger {
        display: none;
        flex-direction: column;
        position: absolute;
        right: 50px;
        top: 70px;
        cursor: pointer;

        span {
            width: 25px;
            height: 2px;
            background-color: $cc-white; 
            
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3) {
                transition: transform .4s ease;
                transform-origin: center;
            }

            &:nth-of-type(2) {
                margin: 5px 0;
            }
        }


        &-trigger {
            display: none;
        }
    }    

    .logo {
        grid-area: logo;

        svg {
            path {
                fill: $cc-white;
            }
        }
    }

    &-content {
        display: grid;
        grid-template-columns: 1fr 200px 1fr;
        grid-template-areas: 'left logo right';
        align-items: center;
        padding: 50px 80px;
        position: relative;

        .navigation {
            &-primary, &-right {
                .list {
                    display: flex;
                    justify-content: flex-end;
                    padding-left: 0;
                    margin-bottom: 0;
        
                    li {
                        list-style: none;
                        margin-bottom: 0;
        
                        a {
                            text-decoration: none;
                        }
        
                        > * {
                            color: $cc-white;
                            position: relative;
                            font-family: $font-base;
                            font-weight: 500;
                            font-size: 14px;
        
                            &:after {
                                content: '';
                                position: absolute;
                                width: 0px;
                                height: 1px;
                                background-color: $cc-white;
                                bottom: -5px;
                                left: 0;
                                transition: width .4s ease;
                            }
                            
                            &:hover, &:active {
                                color: $cc-white;
        
                                &:after {
                                    width: 100%;
                                }
                            }
                        }
        
                        &:not(:last-of-type) {
                            margin-right: 40px;
                        }
                    }
                }
            }
    
            &-right {
                grid-area: right;
                display: flex;
                justify-content: space-between;
                align-items: center;      
                padding-left: 100px;
            }
    
            &-primary {
                grid-area: left;
                padding-right: 100px;           
            }
    
            @include mq($until: 1650px) {
                &-right {
                    padding-left: 60px;                
                }
                   
                &-primary {
                    padding-right: 60px;           
                }
            }
            
            @include mq($until: 1200px) {
                &-right, &-primary {
                    .list {
                        li {
                            &:not(:last-of-type) {
                                margin-right: 20px;
                            }
                        }
                    }
                }
    
                &-right {
                    padding-left: 40px;                
                }
                   
                &-primary {
                    padding-right: 40px;           
                }
            }
        }

        .header-small-dropdown {
            position: absolute;
            top: 0;
            right: 0;

            .languages {
                padding-left: 0;
                position: absolute;
                right: 180px;
                display: flex;
                top: 10px;

                li {
                    list-style: none;
                    margin-left: 5px;
                    padding-left: 5px;

                    a {
                        font-size: 14px;
                        color: white;
                        text-decoration: none;

                        &.active {
                            border-bottom: 1px solid white;
                        }
                    }
                }
            }
    
            .nav-body {
                display: flex;
                flex-direction: column;
                margin-bottom: 0;
                padding-left: 0;
    
                .submenu {
                    position: absolute;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    top: 45px;
                    pointer-events: none;
    
                    &-container {
                        position: relative;
                        list-style: none;                           
    
                        .btn {
                            padding: 15px 25px;                               
                            font-family: $font-base;
                            font-size: 14px;
                            font-weight: 500;
                            transition: background-color .4s ease, color .4s ease;
                            text-align: center;
                            width: 100%;
                            cursor: pointer;
                            text-decoration: none;
                            line-height: 1;
    
                            &.submenu-trigger {
                                background-color: $cc-salmon;
                                color: $cc-white;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-bottom: 1px solid $cc-salmon;
                            }    
    
                            &.submenu-subitem--btn {
                                background-color: $cc-white;
                                color: $cc-salmon;
    
                                &:hover {
                                    background-color: $cc-salmon;
                                    color: $cc-white;
                                }
                            }
                        }
    
                        &:hover {    
                            .submenu {                                   
                                pointer-events: all;
    
                                &-content {
                                    opacity: 1;
                                }
                            }
                        }
                    }
    
                    &-content {
                        opacity: 0;                            
                        transition: opacity .4s ease;
                        display: flex;
                        justify-content: center;
                        width: 100%;
                    }
                    
                    &-sub {
                        display: flex;
                        flex-direction: column;
                        padding-left: 0;
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                        margin: 0;

                        li {
                            list-style: none;
                            width: 100%;
                            margin: 0;

                            a {
                                display: flex;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }

        &.dark {
            .hamburger {
                span {
                    background-color: $cc-primary;
                }
            }

            svg {
                path {
                    fill: $cc-primary;
                }
            }

            a {
                color: $cc-primary !important;

                &:after {
                    background-color: $cc-primary !important;
                }
            }
        }
    
        @include mq($until: 1300px) {
            padding: 50px 40px;
        }
    
    
        @include mq($until: 1200px) {
            padding: 50px 40px;
        }
    
    
        @include mq($until: 900px) {
            padding: 50px 30px;
        }
    
    }

    @include mq($until: $resp-nav) {
        &-content {            
            grid-template-columns: 100%;
            grid-template-rows: repeat(3, auto);
            grid-template-areas:
                'logo'
                'primary'
                'right';
                
            .logo {
                width: 100%;
                max-width: 180px;
            }
    
            .navigation {
                &-primary, &-right {
                    opacity: 0;
                    transform: translateY(-100vh);
                    pointer-events: none;        
                    position: absolute;
                    transition: opacity .4s ease, transform .4s ease;
                }
            }
        }

        .hamburger {
            display: flex;           
            
            &-trigger {                
                &:checked {
                    & ~ .Header-content {
                        width: 100vw;
                        overflow-y: scroll;
                        position: fixed;
                        top: 0;
                        height: 100vh;
                        background-color: $cc-primary;  
                        
                        .hamburger {
                            span {
                                &:nth-of-type(1) {
                                    transform: translateY(7px) rotate(45deg);
                                }

                                &:nth-of-type(2) {
                                    transform: scale(0);
                                }

                                &:nth-of-type(3) {
                                    transform: translateY(-7px) rotate(-45deg);
                                }
                            }
                        }

                        > * {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            ul {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }   

                        .logo {                            
                            width: min-content;
                            position: absolute;
                            left: 0;
                            top: 0;                        
                        }

                        .navigation {
                            &-primary {
                                grid-area: primary;
                                padding-right: 0;
                                margin-top: 100px;
                                margin-bottom: 40px;
                                align-self: flex-end;
                            }
                            
                            &-right {
                                padding-left: 0;
                                align-self: flex-start;
                                margin-bottom: 100px;
                            }

                            &-primary, &-right {
                                opacity: 1;
                                transform: translateY(0);
                                pointer-events: all;
                                position: relative;

                                li {
                                    &:not(:last-of-type) {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }

                        &.dark {
                            .hamburger {
                                span {
                                    background-color: $cc-white;
                                }
                            }
                
                            svg {
                                path {
                                    fill: $cc-white;
                                }
                            }
                
                            a {
                                color: $cc-white !important;

                                &:after {
                                    background-color: $cc-white !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include mq($until: $viewport--sm) {        
        .logo {                                        
            max-width: 150px;   
            
            svg {
                max-width: inherit;
            }
        }

        .hamburger {
            right: 30px;           
        }
    }
}