.page-header {
    .background {
        background-color: $cc-primary;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 300px;
        background: rgb(0,3,16);
        background: linear-gradient(180deg, rgba(0,3,16,0.5) 0%, rgba(0,3,16,0) 100%);
    }
}