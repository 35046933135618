form {
    label {
        display: none !important;
    }

    .ff-form-errors {
        * {
            margin-bottom: 10px;
            color: $cc-red;
            font-family: $font-base;
        }
    }

    .ff-errors {
        display: none;
    }

    input, .cta {
        height: 40px;
    }

    .cta {
        outline: none;
    }

    input, textarea {
        background-color: rgba($cc-white, 0);
        padding: 15px;
        border: 1px solid $cc-grey;
        color: $cc-primary;
        outline: none;
        font-family: $font-base;
        font-weight: 500;
        transition: border-color .4s ease;

        &.ff-has-errors {
            border-color: $cc-red;
        }

        &::placeholder {
            color: $cc-darkGrey;
        }
    }

    input, textarea, .cta {
        font-size: 13px;
    }
}