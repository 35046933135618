h1, h2, h3 {
    font-family: $font-secondary;
    color: $cc-primary;
}

h1 {
    font-size: 64px;

    @include mq($until: 1000px) {
        font-size: 50px;        
    }
    
    @include mq($until: $viewport--md) {
        font-size: 42px;        
    }
   
    @include mq($until: $viewport--sm) {
        font-size: 36px;        
    }
    
    @include mq($until: 440px) {
        font-size: 28px;      
    }
}

h2 {
    font-size: 36px;
    
    @include mq($until: 1000px) {
        font-size: 28px;        
    }
    
    @include mq($until: $viewport--md) {
        font-size: 24px;        
    }
   
    @include mq($until: $viewport--sm) {
        font-size: 20px;        
    }
}

.text {
    p {
        font-family: $font-base;
        color: $cc-darkGrey;
        margin-bottom: 0;
        font-size: 16px;

        @include mq($until: $viewport--sm) {
            font-size: 14px;        
        }
        
        strong {
            color: $cc-primary;
            font-weight: normal;
        }
    }
}