.cta {
    background-color: $cc-primary;
    color: $cc-white;
    text-decoration: none;
    padding: 15px 20px;
    transition: background-color .4s ease, color .4s ease;
    font-family: $font-base;
    font-weight: 500;
    line-height: 1;
    border: 1px solid $cc-primary;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-size: 16px;
    cursor: pointer;

    &:hover, &:focus, &:active {
        color: $cc-primary;
        background-color: $cc-white;
    }

    &.not-filled {
        background-color: rgba($cc-primary, 0);
        padding-left: 0;
        padding-right: 0;
        border: none;
        position: relative;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 14px;
        height: 30px;
        color: $cc-primary;

        &:after {
            content: '';
            width: 50px;
            height: 1px;
            background-color: $cc-primary;
            transition: width .4s ease;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        &:hover {
            &:after {
                width: 100%;
            }
        }

        @include mq($until: $viewport--sm) {
            font-size: 11px;
            padding-bottom: 10px;
            margin-top: 10px;
        }
    }

    &.light {
        background-color: $cc-white;
        color: $cc-primary;
        border: 1px solid $cc-white;

        &:hover, &:focus, &:active {
            color: $cc-white;
            background-color: rgba($cc-primary, 0);
        }
    }

    @include mq($until: $viewport--sm) {
        font-size: 14px;
        padding: 10px 15px;
        height: 40px;
    }
}