.possibilities {
    &-container {
        padding: 120px 30px;

        @include mq($until: $viewport--sm) {
            padding: 80px 30px;
        }
    }

    &-block {
        margin-top: 120px;
        justify-content: center;

        &--websites {
            display: grid;
            grid-template-columns: repeat(3, 480px);

            &.amount {
                &-1, &-2 {
                    display: flex;
                    justify-content: center;
                }
            }           
        }

        &--website {
            width: 480px;
            text-decoration: none;
            padding: 20px 30px;

            .image {
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                width: 100%;
                height: 560px;
                transition: transform .4s ease;

                &-container {
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &-overlay {
                    position: absolute;
                    max-width: 300px;
                    max-height: 350px;
                    width: 100%;
                    height: 100%;
                    background-color: $cc-primary;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $cc-white;
                    font-size: 12px;
                    font-weight: 500;
                    z-index: 1;
                    font-family: $font-base;
                    transform: scale(0);
                    transition: transform .4s ease;
                }
            }

            .info {
                display: flex;
                flex-direction: column;
                align-items: center;    
                margin-top: 20px;            

                .name {
                    font-family: $font-base;
                    font-size: 16px;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: $cc-primary;
                }

                .name, .star {
                    line-height: 1;
                }

                &.title-left {
                    align-items: flex-start;

                    .name {
                        font-weight: normal;
                        color: $cc-darkGrey;
                    }

                    .stars {
                        .star {
                            svg {
                                path {
                                    fill: $cc-darkGrey;
                                }
                            }
                        }
                    }
                }
            }

            &.has-site {
                &:hover {
                    .image {
                        transform: scale(1.05);

                        &-overlay {
                            transform: scale(1);
                        }
                    }
                }
            }
        }

        &--textblock, &--textblocks {
            .textblock {
                @include mq($until: $viewport--sm) {
                    padding: 0 !important;
                    width: 100% !important;
                    padding-left: 0 !important;
                    
                    &--images {
                        justify-content: flex-end !important;
                    }
                    
                    &--text {
                        margin-left: 0 !important;
                    }
                }
            }
        }

        @include mq($until: 1440px) {
            &--websites {
                grid-template-columns: repeat(3, 360px);
            }
            
            &--website {
                width: 360px;

                .image {
                    height: 400px;

                    &-overlay {
                        max-width: 260px;
                    }
                }
            }
        }

        @include mq($until: 1100px) {
            &--websites {
                grid-template-columns: repeat(3, 300px);
            }
            
            &--website {
                padding: 20px 10px;
                width: 300px;

                .image {
                    height: 350px;

                    &-overlay {
                        max-width: 240px;
                        max-height: 310px;
                    }
                }
            }
        }

        @include mq($until: 960px) {
            &--websites {
                grid-template-columns: repeat(2, 300px);
            }
        }
        
        @include mq($until: $viewport--sm) {
            margin-top: 60px;

            &--websites {
                grid-template-columns: 300px;

                &.amount-2 {
                    flex-wrap: wrap;
                }
            }
        }
       
        @include mq($until: 450px) {
            &--website {
                .info {
                    margin-top: 15px;
                    
                    .name {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &-options {
        max-width: 960px;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding: 0 30px;
        flex-wrap: wrap;

        label {
            font-size: 64px;
            font-family: $font-secondary;
            color: $cc-grey;
            cursor: pointer;
            transition: color .4s ease;
            padding:  10px;

            &:hover {
                color: $cc-primary;
            }

            &.active {
                color: $cc-primary;
            }

            @include mq($until: $viewport--md) {
                font-size: 44px;
            }
           
            @include mq($until: $viewport--sm) {
                font-size: 30px;
            }
        }
    }

    &-selected {
        position: relative;

        > div {
            pointer-events: none;
        }

        input {
            &[type=radio] {
                display: none;

                &:checked {
                    & ~ .possibilities-selected--content-container {
                        position: relative;
                        
                        .possibilities-selected--content {
                            pointer-events: all;
                            opacity: 1;
                            
                            .possibilities-block {
                                transform: scale(1);                            
                            }
                        }
                    }
                }

                &:not(:checked) {
                    & ~ .possibilities-selected--content-container {
                        .possibilities-selected--content {
                            height: 0;
                        }
                    }
                }
            }
        }

        &--content {            
            pointer-events: none;
            flex-direction: column;
            align-items: center;
            opacity: 0;
            transition: opacity .4s ease;

            &-container {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
            }
            
            .possibilities-block {
                transform: scale(0);
                transition: transform .4s .2s ease;
            }

            .intro {
                max-width: 650px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                padding-top: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .cta {
                    margin-top: 30px;
                }
            }
        }
    }
}