/* Colors: Custom */
//Example: $cc-[NAME]: #......

// [PROJECTNAME] colors
$cc-white: white;
$cc-lightestGrey: #f0f0f0 !default;
$cc-lightGrey: #F4F4F4 !default;
$cc-grey: #E5E5E5 !default;
$cc-darkGrey: #ADADAD !default;
$cc-darkerGrey: #8B8B8B !default;
$cc-darkestGrey: #3B3B3B !default;
$cc-black:#000000 !default;
$cc-blackShade: #435572 !default;
$cc-primary: #0B1D40 !default;
$cc-goldShade: #FFFBF6 !default;
$cc-red: rgb(222, 67, 67) !default;
$cc-salmon: #FC8787 !default;
