.wave {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='1920' height='480' viewBox='0 0 1920 480' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 225.48C219.4 149.466 617.9 -62.3858 947 17.8516C1166.4 72.7508 1673.1 318.387 1920 225.48V479.48H0V225.48Z' fill='%23FFFBF6'/%3E%3C/svg%3E");
    background-size: auto 100%;
    background-repeat: repeat-x;
    background-position: center center;
    height: 280px;
    transform: rotate(180deg);
    z-index: -1;
    position: relative;
    
    &.updown {
        transform: rotate(0);
    }

    @include mq($until: $viewport--sm) {
        height: 150px;
    }
}