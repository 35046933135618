.PrivacyPagesPage {
    .privacy-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1200px;
        width: 100%;
        margin: 100px auto 200px;
        padding: 0 30px;

        h1 {
            text-align: center;
        }

        &--text {
            margin-top: 80px;

            * {
                font-family: $font-base;
                color: $cc-darkGrey;
            }

            h4, strong {
                color: $cc-primary;
                font-size: 16px;
                font-weight: 500;
            }

            ul {
                li {
                    list-style: none;
                    position: relative;
                    padding-left: 0;

                    &:before {
                        content: '';
                        position: absolute;
                        width: 10px;
                        height: 1px;
                        background-color: $cc-darkGrey;
                        left: -25px;
                        top: 10px;
                    }
                }
            }

            .caption {
                font-style: italic;
            }
        }

        @include mq($until: $viewport--sm) {
            margin: 40px auto 50px;

            &--text {
                margin-top: 40px;

                * {
                    font-size: 14px;
                }
            }
        }
    }
}